.App {
    text-align: center;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .smoky {
    font-size: 3rem;
    position: relative;
    animation: smokyAnimation 3s ease-in-out infinite;
    color: #fff;
  }
  .smoky::before,
  .smoky::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
  }
  .smoky::before {
    color: #8a2be2;
    z-index: -1;
    animation: smokyBefore 3s ease-in-out infinite;
  }
  .smoky::after {
    color: #48d1cc;
    z-index: -2;
    animation: smokyAfter 3s ease-in-out infinite;
  }
  @keyframes smokyAnimation {
    0%, 100% {
      transform: scale(1);
      filter: blur(0px);
    }
    50% {
      transform: scale(1.2);
      filter: blur(2px);
    }
  }
  @keyframes smokyBefore {
    0%, 100% {
      transform: translate(-10px, 0);
    }
    50% {
      transform: translate(20px, -10px);
    }
  }
  @keyframes smokyAfter {
    0%, 100% {
      transform: translate(10px, 5px);
    }
    50% {
      transform: translate(-20px, 10px);
    }
  }
  